import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const CrispChat = () => {
  const USER = JSON.parse(localStorage.getItem('AEGIS_USER'));
  console.log({USER});
  useEffect(() => {
    /**
     * This code is to add user name to CRISP chatbot
     */
    const dealer = {
      id: USER?.dealer_code,
      name: USER?.name,
      email: USER?.email,
    }
    if (USER) {
      // Function to set the user nickname
      const setCrispUserNickname = () => {
        if (window.$crisp) {
          window.$crisp.push(['set', 'user:nickname', [`${dealer.name} - 1.0`]]);
          window.$crisp.push(['set', 'user:email', [dealer.email]]);
        }
      };

      // Check if the Crisp script is already loaded
      if (window.$crisp) {
        setCrispUserNickname();
      } else {
        // Wait for the Crisp script to load and then set the nickname
        const intervalId = setInterval(() => {
          if (window.$crisp) {
            setCrispUserNickname();
            clearInterval(intervalId);
          }
        }, 100);
      }
    }
  });
const route = window.location.pathname;
if(route.includes('insurance'))
return (
  <Helmet>
    <script type="text/javascript">
      {`window.$crisp = [];
      window.CRISP_WEBSITE_ID = "8541fb48-6e88-4c27-a636-cdfa62bd15bd";
      (function () {
        d = document;
        s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })(); `}
    </script>
  </Helmet>

);
return null;
}
export default CrispChat;
